import {useState} from 'react'
import styled from 'styled-components/native'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import {RouteSignedOutParams} from '@ltb/libs/navigation/signed-out-navigator'
import {Button} from '@ltb/libs/components/button'
import {TextInput} from '@ltb/libs/components/text-input'
import {useAuthentication} from '@ltb/libs/contexts/authentication-context'
import {Iconography, fontS} from '@ltb/libs/design-system'
import {useLoginEmailPasswordMutation} from '@ltb/libs/graphql/core'
import {Header} from '@ltb/libs/components/header'

const LoginWrapper = styled.View`
  display: flex;
  flex-grow: 1;
  background-color: ${({theme}) => theme.background};
  align-items: center;
  justify-content: center;
`

const InternalWrapper = styled.View`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  width: 100%;
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;

  align-items: center;
  justify-content: center;

  gap: 10px;
`

const IconWrapper = styled.View`
  padding: 50px;
`

const ErrorText = styled.Text`
  color: ${({theme}) => theme.error.background};
  ${fontS}
`

type LoginProps = {
  storybookLoading?: boolean
  storybookError?: string
  storybookComplete?: boolean
}

export const Login = ({storybookLoading, storybookError, storybookComplete}: LoginProps) => {
  const navigation = useNavigation<NavigationProp<RouteSignedOutParams>>()

  const {setAuthenticationToken} = useAuthentication()
  const [login, {data, error, loading}] = useLoginEmailPasswordMutation({
    onCompleted: async ({loginEmailPassword}) => {
      if (!loginEmailPassword?.token || !loginEmailPassword?.user?.id) {
        console.log('Login failed')
        return
      }
      await setAuthenticationToken(loginEmailPassword.token)
    },
    onError: () => {
      console.warn('Login failed')
    },
  })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async () => {
    await login({
      variables: {
        email,
        password,
      },
    })
  }

  const isComplete =
    storybookComplete || (Boolean(data?.loginEmailPassword.token) && Boolean(data?.loginEmailPassword?.user?.id))
  const isErrored = storybookError || (error !== undefined && !data)
  const disabled = storybookLoading || loading || isComplete

  return (
    <LoginWrapper>
      <Header leftIcon="ChevronLeft" leftOnPress={() => navigation.goBack()} title="Log-in" />
      <InternalWrapper>
        <IconWrapper>
          <Iconography icon="LittleTinBox" size={70} />
        </IconWrapper>
        <TextInput
          label="Email"
          id="email"
          autoCapitalize="none"
          autoComplete="email"
          onChangeText={setEmail}
          disabled={disabled}
          variant={isErrored ? 'error' : undefined}
          testID="email-input"
        />
        <TextInput
          label="Password"
          id="password"
          textContentType="password"
          secureTextEntry
          autoComplete="current-password"
          onChangeText={setPassword}
          disabled={disabled}
          variant={isErrored ? 'error' : undefined}
          testID="password-input"
        />
        <ErrorText>{isErrored ? 'Login failed' : ''}</ErrorText>
        <Button layout="responsive" type="info" size="medium" onPress={handleLogin} disabled={disabled}>
          {isComplete ? 'Logged in' : disabled ? 'Logging in...' : 'Login'}
        </Button>
        {/* TODO: Implement forgot password flow */}
        <Button layout="responsive" type="info" size="medium" variant="linked" onPress={() => {}} disabled={disabled}>
          I have forgotten my details
        </Button>
      </InternalWrapper>
    </LoginWrapper>
  )
}
