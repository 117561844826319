import {Button} from '@ltb/libs/components/button'
import {useUser} from '@ltb/libs/contexts/user-context'
import {fontLContrast, fontM, Iconography} from '@ltb/libs/design-system'
import styled from 'styled-components/native'

const ScreenWrapper = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const Header = styled.Text`
  ${fontLContrast}
  color: ${({theme}) => theme.primary};
  padding-bottom: 4px;
`

const Subtext = styled.Text`
  ${fontM}
  color: ${({theme}) => theme.primary};
  padding-bottom: 16px;
`

export const UserLoadError = () => {
  const {refetchUser} = useUser()
  return (
    <ScreenWrapper>
      <Iconography icon="Help" size={48} />
      <Header>Looks like there was a problem</Header>
      <Subtext>Please try again</Subtext>
      <Button type="info" onPress={() => refetchUser()} size="medium" layout="fixed">
        Refetch
      </Button>
    </ScreenWrapper>
  )
}
