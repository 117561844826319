import React, {createContext, useState, useContext, useEffect, PropsWithChildren} from 'react'
import {getItem, setItem, removeItem} from '@ltb/libs/storage'

type SetAuthenticationToken = (token: string) => void

type AuthenticationContextType = {
  token: string | null
  setAuthenticationToken: SetAuthenticationToken
  deleteAuthenticationToken: () => void
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
  token: null,
  setAuthenticationToken: () => {},
  deleteAuthenticationToken: () => {},
})

export const AuthenticationProvider = ({children}: PropsWithChildren) => {
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const getToken = async () => {
      const userToken = await getItem('ltb-token')
      if (!userToken) return
      setToken(userToken)
    }
    void getToken()
  }, [])

  const deleteAuthenticationToken = () => {
    void removeItem('ltb-token')
    setToken(null)
  }

  const setAuthenticationToken = async (newToken: string) => {
    await setItem('ltb-token', newToken)
    setToken(newToken)
  }

  return (
    <AuthenticationContext.Provider value={{token, deleteAuthenticationToken, setAuthenticationToken}}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export const useAuthentication = (): AuthenticationContextType => useContext(AuthenticationContext)
