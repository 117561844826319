import styled from 'styled-components/native'
import {fontM} from '@ltb/libs/design-system/'

const LoadingWrapper = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const LoadingText = styled.Text`
  ${fontM}
  color: ${({theme}) => theme.primary};
`

export const UserLoading = () => {
  return (
    <LoadingWrapper>
      <LoadingText>Loading (Temp)</LoadingText>
    </LoadingWrapper>
  )
}
