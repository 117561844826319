import {ApolloQueryResult, NetworkStatus} from '@apollo/client'
import {CurrentUserQuery, UserFragment, useCurrentUserQuery} from '@ltb/libs/graphql/core'
import {ROUTE_SPLASH} from '@ltb/libs/navigation/constants'
import {RouteRootParams} from '@ltb/libs/navigation/root-navigator'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import React, {createContext, useState, useContext, PropsWithChildren} from 'react'
import {apolloClient} from '@ltb/libs/apollo'
import {useAuthentication} from '../authentication-context'

type UserProviderProps = {
  user: UserFragment | null
}

type UserContextType = {
  user: UserFragment | null
  setUser: React.Dispatch<React.SetStateAction<UserFragment | null>>
  refetchUser: () => Promise<ApolloQueryResult<CurrentUserQuery>> | null
  userNetworkStatus: NetworkStatus
  logoutUser: () => void
}

/* istanbul ignore next */
export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {},
  refetchUser: () => null,
  userNetworkStatus: NetworkStatus.ready,
  logoutUser: () => {},
})

export const UserProvider = ({user: initialUser = null, children}: PropsWithChildren<UserProviderProps>) => {
  const [user, setUser] = useState<UserFragment | null>(initialUser)
  const {deleteAuthenticationToken} = useAuthentication()

  const navigation = useNavigation<NavigationProp<RouteRootParams>>()

  const {networkStatus, refetch} = useCurrentUserQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (!data.currentUser) {
        setUser(data.currentUser)
      }
    },
  })

  if (!user) {
    navigation.navigate(ROUTE_SPLASH)
  }

  const logoutUser = () => {
    void apolloClient.clearStore()
    deleteAuthenticationToken()
    setUser(null)
  }

  return (
    <UserContext.Provider value={{user, setUser, userNetworkStatus: networkStatus, refetchUser: refetch, logoutUser}}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
