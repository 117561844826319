import {CategoryFragment} from '@ltb/libs/graphql/cms'
import {Card} from '../card'
import {
  CardContent,
  CardDescription,
  CardInternal,
  CardLeftInternal,
  CardTitle,
  PremiumUpsell,
} from './category-card.styles'
import {Iconography} from '@ltb/libs/design-system'
import {Pressable} from '../pressable'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import {RouteSignedInParams} from '@ltb/libs/navigation/signed-in-navigator'
import {ROUTE_GENERIC_QUESTION, ROUTE_SUBSCRIBE} from '@ltb/libs/navigation/constants'
import {usePlan} from '@ltb/libs/contexts/plan-context'

type CategoryCardProps = {
  category: CategoryFragment
}

export const CategoryCard = ({category}: CategoryCardProps) => {
  const {plan} = usePlan()
  const navigation = useNavigation<NavigationProp<RouteSignedInParams>>()
  if (!plan) return null
  if (!category.enabled) return null
  if (!category.visible) return null

  const isInTier = category?.plans?.includes(plan.tier)

  const onPressNavigation = () => {
    if (isInTier) {
      navigation.navigate(ROUTE_GENERIC_QUESTION, {
        categoryId: category.id,
      })
      return
    }
    navigation.navigate(ROUTE_SUBSCRIBE)
  }

  return (
    <Pressable onPress={onPressNavigation}>
      <Card type="midground" includePadding>
        <CardInternal>
          <CardLeftInternal isInTier={isInTier}>
            <Iconography icon="Star" size={30} />
            <CardContent>
              <CardTitle>{category.title?.key}</CardTitle>
              <CardDescription>{category.description?.key}</CardDescription>
            </CardContent>
          </CardLeftInternal>
          {isInTier ? <Iconography icon="ChevronRight" size={24} /> : <PremiumUpsell>PREMIUM</PremiumUpsell>}
        </CardInternal>
      </Card>
    </Pressable>
  )
}
