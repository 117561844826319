import {fontS, fontMContrast, fontSContrast} from '@ltb/libs/design-system'
import styled from 'styled-components/native'

export const CardInternal = styled.View`
  flex-direction: row;
  align-items: center
  justify-content: space-between;
  flex-grow: 1;
`

export const CardLeftInternal = styled.View<{isInTier: boolean}>`
  flex-direction: row;
  align-items: center;
  ${({isInTier}) => (isInTier ? 'opacity: 1' : 'opacity: 0.5')};
`

export const CardContent = styled.View`
  flex-direction: column;
  padding-left: 20px;
`

export const CardTitle = styled.Text`
  ${fontMContrast}
  color: ${({theme}) => theme.primary};
`

export const CardDescription = styled.Text`
  ${fontS}
  color: ${({theme}) => theme.primary};
`

export const PremiumUpsell = styled.View`
  ${fontSContrast}
  color: ${({theme}) => theme.primary};
`
