import {createNativeStackNavigator} from '@react-navigation/native-stack'
import {useTheme} from 'styled-components/native'
import {
  ROUTE_BASIC_INFO,
  ROUTE_CREATE_PLAN,
  ROUTE_DRAWER_MODAL,
  ROUTE_PLAN_HOME,
  ROUTE_PREPAREDNESS_QUESTION,
  ROUTE_SIGNED_IN_LANDING,
  ROUTE_WILL_CONFIRMATION,
  ROUTE_GENERIC_QUESTION,
  ROUTE_SUBSCRIBE,
} from './constants'
import {useUser} from '../contexts/user-context'
import {Drawer} from '../screens/drawer'
import {SignedInLanding} from '../screens/signed-in-landing'
import {BasicInfoOne, BasicInfoTwo, BasicInfoThree} from '../screens/basic-info'
import {PlanLanding} from '../screens/plan-landing'
import {CreatePlan} from '../screens/create-plan'
import {GenericQuestion} from '../screens/generic-question'
import {NetworkStatus} from '@apollo/client'
import {UserLoadError} from '../screens/user-load-error'
import {UserLoading} from '../screens/user-loading'

export type RouteSignedInParams = {
  [ROUTE_SIGNED_IN_LANDING]: undefined
  [ROUTE_DRAWER_MODAL]: undefined
  [ROUTE_CREATE_PLAN]: undefined
  [ROUTE_BASIC_INFO]: undefined
  [ROUTE_PLAN_HOME]: undefined
  [ROUTE_WILL_CONFIRMATION]: undefined
  [ROUTE_PREPAREDNESS_QUESTION]: undefined
  [ROUTE_GENERIC_QUESTION]: {
    categoryId: string
    screenId?: string
  }
  [ROUTE_SUBSCRIBE]: undefined
}

const Stack = createNativeStackNavigator<RouteSignedInParams>()

export const SignedInNavigator = () => {
  const theme = useTheme()
  const {user, userNetworkStatus} = useUser()
  const background = {backgroundColor: theme.background}

  if (userNetworkStatus === NetworkStatus.loading && !user) {
    return <UserLoading />
  }

  if (userNetworkStatus === NetworkStatus.error) {
    return <UserLoadError />
  }

  return (
    <Stack.Navigator
      initialRouteName={ROUTE_SIGNED_IN_LANDING}
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
        contentStyle: background,
      }}>
      <Stack.Screen name={ROUTE_SIGNED_IN_LANDING} component={SignedInLanding} />
      <Stack.Screen name={ROUTE_CREATE_PLAN} component={CreatePlan} />
      <Stack.Screen name={ROUTE_PLAN_HOME} component={PlanLanding} />
      <Stack.Screen
        name={ROUTE_DRAWER_MODAL}
        component={Drawer}
        options={{
          animation: 'fade',
          presentation: 'transparentModal',
          headerShown: false,
          contentStyle: {
            backgroundColor: 'transparent',
          },
        }}
      />
      <Stack.Screen name={ROUTE_BASIC_INFO} component={BasicInfoOne} />
      <Stack.Screen name={ROUTE_WILL_CONFIRMATION} component={BasicInfoTwo} />
      <Stack.Screen name={ROUTE_PREPAREDNESS_QUESTION} component={BasicInfoThree} />
      <Stack.Screen name={ROUTE_GENERIC_QUESTION} component={GenericQuestion} />
      <Stack.Screen name={ROUTE_SUBSCRIBE} component={PlanLanding} />
    </Stack.Navigator>
  )
}
