export enum fonts {
  OUTFIT_BLACK = 'Outfit-Black',
  OUTFIT_EXTRA_BOLD = 'Outfit-ExtraBold',
  OUTFIT_BOLD = 'Outfit-Bold',
  OUTFIT_SEMI_BOLD = 'Outfit-SemiBold',
  OUTFIT_REGULAR = 'Outfit-Regular',
  OUTFIT_MEDIUM = 'Outfit-Medium',
  OUTFIT_LIGHT = 'Outfit-Light',
  OUTFIT_EXTRA_LIGHT = 'Outfit-ExtraLight',
  OUTFIT_THIN = 'Outfit-Thin',
}

export type FontTypes = 'XLContrast' | 'XL' | 'LContrast' | 'L' | 'MContrast' | 'M' | 'SContrast' | 'S' | 'XS'

type FontWeights = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'

const fontFamily: Record<FontTypes, fonts> = {
  XLContrast: fonts.OUTFIT_BOLD,
  XL: fonts.OUTFIT_LIGHT,
  LContrast: fonts.OUTFIT_BOLD,
  L: fonts.OUTFIT_LIGHT,
  MContrast: fonts.OUTFIT_BOLD,
  M: fonts.OUTFIT_LIGHT,
  SContrast: fonts.OUTFIT_BOLD,
  S: fonts.OUTFIT_LIGHT,
  XS: fonts.OUTFIT_LIGHT,
}

const fontWeights: Record<FontTypes, FontWeights> = {
  XLContrast: '700',
  XL: '300',
  LContrast: '700',
  L: '300',
  MContrast: '700',
  M: '300',
  SContrast: '700',
  S: '300',
  XS: '300',
}

const fontSizes: Record<FontTypes, number> = {
  XLContrast: 40,
  XL: 40,
  LContrast: 20,
  L: 20,
  MContrast: 15,
  M: 15,
  SContrast: 13,
  S: 13,
  XS: 10,
}

const fontLineHeight: Record<FontTypes, number> = {
  XLContrast: 40, // Need to check this
  XL: 40, // Need to check this
  LContrast: 20, // Need to check this
  L: 20, // Need to check this
  MContrast: 17,
  M: 15, // Need to check this
  SContrast: 13, // Need to check this
  S: 14,
  XS: 10, // Need to check this
}

export const storybookFont = (type: FontTypes) => ({
  fontFamily: fontFamily[type],
  fontSize: fontSizes[type],
  fontWeight: fontWeights[type],
  fontLineHeight: fontLineHeight[type],
})

const fontGenerator = (type: FontTypes): string => {
  return `
    font-family: ${fontFamily[type]};
    font-size: ${fontSizes[type]}px;
    font-weight: ${fontWeights[type]};
    line-height: ${fontLineHeight[type]}px;
  `
}

export const fontXS = fontGenerator('XS')
export const fontS = fontGenerator('S')
export const fontSContrast = fontGenerator('SContrast')
export const fontM = fontGenerator('M')
export const fontMContrast = fontGenerator('MContrast')
export const fontL = fontGenerator('L')
export const fontLContrast = fontGenerator('LContrast')
export const fontXL = fontGenerator('XL')
export const fontXLContrast = fontGenerator('XLContrast')
