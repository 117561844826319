// Root level screems
export const ROUTE_SPLASH = 'Loading'
export const ROUTE_SIGNED_OUT_STACK = 'RouteSignedOutStack'
export const ROUTE_SIGNED_IN_STACK = 'RouteSignedInStack'
export const ROUTE_NOT_FOUND = '404'

// Signed out screens
export const ROUTE_SIGNED_OUT_LANDING = 'Welcome'
export const ROUTE_LOGIN = 'Log-in'
export const ROUTE_SIGN_UP = 'Sign-in'

// Signed in screens
export const ROUTE_DRAWER_MODAL = 'Menu'
export const ROUTE_SIGNED_IN_LANDING = 'Home'
export const ROUTE_CREATE_PLAN = 'CreatePlan'
export const ROUTE_PLAN_HOME = 'PlanHome'
export const ROUTE_BASIC_INFO = 'Basic Info'
export const ROUTE_WILL_CONFIRMATION = 'WillConfirmation'
export const ROUTE_PREPAREDNESS_QUESTION = 'PreparednessQuestion'
export const ROUTE_GENERIC_QUESTION = 'GenericQuestion'
export const ROUTE_SUBSCRIBE = 'Subscribe'
