import React from 'react'
import styled from 'styled-components/native'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import {fontMContrast} from '@ltb/libs/design-system'
import {Header} from '@ltb/libs/components/header'
import {RouteSignedInParams} from '@ltb/libs/navigation/signed-in-navigator'
import {Card} from '@ltb/libs/components/card'
import {Pressable} from '@ltb/libs/components/pressable'
import {ROUTE_BASIC_INFO} from '@ltb/libs/navigation/constants'
import {useCMS} from '@ltb/libs/contexts/cms-context'
import {CategoryCard} from '@ltb/libs/components/category-card'

const BasicInfoWrapper = styled.View`
  display: flex;
  flex-grow: 1;
  background-color: ${({theme}) => theme.background};
  align-items: center;
  justify-content: center;
`

const InternalWrapper = styled.View`
  max-width: 800px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 10px;
`

const SectionsWrapper = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`

const SectionTitle = styled.Text`
  color: ${({theme}) => theme.secondary};
  ${fontMContrast}
`

const StyledCard = styled(Card)`
  max-width: 150px;
`

type PlanLandingProps = {
  storybookLoading?: boolean
  storybookError?: string
  storybookComplete?: boolean
}

export const PlanLanding = ({}: PlanLandingProps) => {
  const navigation = useNavigation<NavigationProp<RouteSignedInParams>>()
  const {categories} = useCMS()

  const isOwner = false

  return (
    <BasicInfoWrapper>
      <Header
        leftIcon="ChevronLeft"
        leftOnPress={() => navigation.goBack()}
        title={`${isOwner ? 'Your' : 'Their'} Plan`}
      />
      <InternalWrapper>
        <SectionsWrapper>
          <Pressable onPress={() => navigation.navigate(ROUTE_BASIC_INFO)}>
            <StyledCard type="midground" includePadding>
              <SectionTitle>The Basic's</SectionTitle>
            </StyledCard>
          </Pressable>
          {categories.map(category => (
            <CategoryCard key={category.id} category={category} />
          ))}
        </SectionsWrapper>
      </InternalWrapper>
    </BasicInfoWrapper>
  )
}
