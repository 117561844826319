import {PlanFragment} from '@ltb/libs/graphql/core'
import React, {createContext, useState, useContext, PropsWithChildren} from 'react'

type PlanProviderProps = {
  mockedPlan?: PlanFragment
}

type PlanContextType = {
  plan: PlanFragment | null
  setPlan: React.Dispatch<React.SetStateAction<PlanFragment | null>>
}

/* istanbul ignore next */
export const PlanContext = createContext<PlanContextType>({
  plan: null,
  setPlan: () => {},
})

export const PlanProvider = ({mockedPlan, children}: PropsWithChildren<PlanProviderProps>) => {
  const [plan, setPlan] = useState<PlanFragment | null>(mockedPlan || null)

  return <PlanContext.Provider value={{plan, setPlan}}>{children}</PlanContext.Provider>
}

export const usePlan = () => useContext(PlanContext)
