import {useUser} from '@ltb/libs/contexts/user-context'
import {Button} from '@ltb/libs/components/button'
import {Header} from '@ltb/libs/components/header'
import {
  CardContent,
  CardContentWrapper,
  CardInternalWrapper,
  CardLeft,
  CardTitle,
  ContentText,
  HeadingText,
  InternalContent,
  InternalView,
  LandingWrapper,
} from './signed-in-landing.styles'
import {useNavigation, NavigationProp} from '@react-navigation/native'
import {RouteSignedInParams} from '@ltb/libs/navigation/signed-in-navigator'
import {ROUTE_CREATE_PLAN, ROUTE_DRAWER_MODAL, ROUTE_PLAN_HOME} from '@ltb/libs/navigation/constants'
import {Card} from '@ltb/libs/components/card'
import {Iconography} from '@ltb/libs/design-system'
import {Pressable} from '@ltb/libs/components/pressable'

export const SignedInLanding = () => {
  const navigation = useNavigation<NavigationProp<RouteSignedInParams>>()
  const {user} = useUser()

  if (user?.plans.length === 0) {
    return (
      <LandingWrapper>
        <Header leftIcon="LittleTinBox" rightIcon="Menu" rightOnPress={() => navigation.navigate(ROUTE_DRAWER_MODAL)} />
        <InternalView>
          <InternalContent>
            <HeadingText>You haven't created any plans</HeadingText>
            <ContentText>
              Our plans come with an array of tools to safeguard you and your family's information.
            </ContentText>
            <Button
              layout="fixed"
              size="large"
              type="info"
              onPress={() => {
                navigation.navigate(ROUTE_CREATE_PLAN)
              }}>
              Create plan
            </Button>
          </InternalContent>
        </InternalView>
      </LandingWrapper>
    )
  }

  return (
    <LandingWrapper>
      <Header leftIcon="LittleTinBox" rightIcon="Menu" rightOnPress={() => navigation.navigate(ROUTE_DRAWER_MODAL)} />
      <InternalContent>
        <HeadingText>Your plans</HeadingText>

        <ContentText>Select the plan you want to continue with below.</ContentText>
        <Card type="midground" includePadding>
          <Pressable onPress={() => navigation.navigate(ROUTE_PLAN_HOME)}>
            <CardInternalWrapper>
              <CardLeft>
                <Iconography icon="Star" size={24} />
                <CardContentWrapper>
                  <CardTitle>Your Plan</CardTitle>
                  <CardContent>{user?.email}</CardContent>
                </CardContentWrapper>
              </CardLeft>
              <Iconography icon="ChevronRight" size={24} />
            </CardInternalWrapper>
          </Pressable>
        </Card>
      </InternalContent>
    </LandingWrapper>
  )
}
