import React from 'react'
import {InternalIcons} from './internal-icons'
import {CompanyLogos} from './company-logos'
import {useTheme} from 'styled-components/native'
import {palette} from '../palette'

export type Icons = keyof typeof InternalIcons | keyof typeof CompanyLogos

type IconographyProps = {
  icon: Icons
  size: number
  color?: palette
}

const iconMap = {...InternalIcons, ...CompanyLogos}

export const Iconography = ({icon, size, color}: IconographyProps) => {
  const Component = iconMap[icon]
  const theme = useTheme()
  const fill = color || theme.primary

  return <Component width={size} height={size} fill={fill} viewBox={`0 0 24 24`} testID={`icon-${icon}`} />
}
