import Box from './assets/internal-icons/box.svg'
import CheckBox from './assets/internal-icons/check_box.svg'
import CheckCircle from './assets/internal-icons/check_circle.svg'
import ChevronLeft from './assets/internal-icons/chevron_left.svg'
import ChevronRight from './assets/internal-icons/chevron_right.svg'
import Circle from './assets/internal-icons/circle.svg'
import Edit from './assets/internal-icons/edit.svg'
import Help from './assets/internal-icons/help.svg'
import Identity from './assets/internal-icons/identity.svg'
import Loading from './assets/internal-icons/loading.svg'
import Menu from './assets/internal-icons/menu.svg'
import Pdf from './assets/internal-icons/pdf.svg'
import Picture from './assets/internal-icons/picture.svg'
import Star from './assets/internal-icons/star.svg'
import Trash from './assets/internal-icons/trash.svg'
import Upload from './assets/internal-icons/upload.svg'
import Video from './assets/internal-icons/video.svg'
import Will from './assets/internal-icons/will.svg'

export const InternalIcons = {
  Box,
  CheckBox,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Circle,
  Edit,
  Help,
  Identity,
  Loading,
  Menu,
  Pdf,
  Picture,
  Star,
  Trash,
  Upload,
  Video,
  Will,
}
